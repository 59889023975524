// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portfolio-page-primary: mat.define-palette(mat.$indigo-palette);
$portfolio-page-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portfolio-page-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portfolio-page-theme: mat.define-light-theme((color: (primary: $portfolio-page-primary,
        accent: $portfolio-page-accent,
        warn: $portfolio-page-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portfolio-page-theme);

* {
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-width: auto;
  scrollbar-color: #fa7902 #1f2c47;
}

.main-content {
  position: relative;
  overflow: hidden;
  text-shadow: 2px 2px #1f2c47;
}

app-main-content {
  display: flex;
  flex-direction: column;
}

*:hover {
  cursor: url('/assets/img/background/icons8-mauszeiger.png'), auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Poppins, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1f2c47;
}

::-webkit-scrollbar-thumb {
  background: #FA7902;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #FF4747;
}

::-webkit-scrollbar-button {
  border-style: solid;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-button:vertical:decrement {
  border-width: 0 4px 8px 4px;
  border-color: transparent transparent #FA7902 transparent;
  background-color: #1f2c47;
}

::-webkit-scrollbar-button:vertical:decrement:hover {
  border-color: transparent transparent #FF4747 transparent;
}

::-webkit-scrollbar-button:vertical:increment {
  border-width: 8px 4px 0 4px;
  border-color: #FA7902 transparent transparent transparent;
  background-color: #1f2c47;
}

::-webkit-scrollbar-button:vertical:increment:hover {
  border-color: #FF4747 transparent transparent transparent;
}

::-webkit-scrollbar-button:horizontal:decrement {
  border-width: 4px 8px 4px 0;
  border-color: transparent #FA7902 transparent transparent;
  background-color: #1f2c47;
}

::-webkit-scrollbar-button:horizontal:decrement:hover {
  border-color: transparent #FF4747 transparent transparent;
}

::-webkit-scrollbar-button:horizontal:increment {
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #FA7902;
  background-color: #1f2c47;
}

::-webkit-scrollbar-button:horizontal:increment:hover {
  border-color: transparent transparent transparent #FF4747;
}

/* poppins-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/poppins-v20-latin-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-100italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/poppins-v20-latin-100italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/poppins-v20-latin-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-200italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/poppins-v20-latin-200italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/poppins-v20-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/poppins-v20-latin-300italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/poppins-v20-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/poppins-v20-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/poppins-v20-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/poppins-v20-latin-500italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/poppins-v20-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/fonts/poppins-v20-latin-600italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/poppins-v20-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/poppins-v20-latin-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/poppins-v20-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-800italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/fonts/poppins-v20-latin-800italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/poppins-v20-latin-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-900italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/poppins-v20-latin-900italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
